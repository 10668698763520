import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import { Typography, Grid } from '@material-ui/core';

import { Layout, Header, Seo, MapCard, Footer } from '@components';
import { StyledContainer } from './index.styled';
import { TIndexPageProperties } from './index.types';

export default function IndexPage({ data }: TIndexPageProperties) {
    const { allMarkdownRemark } = data;

    const {
        node: { frontmatter: homePage },
    } = allMarkdownRemark.edges.find(
        edge => edge.node.frontmatter.title === 'Home'
    );

    const maps = allMarkdownRemark.edges.filter(
        edge => edge.node.frontmatter.type === 'map'
    );

    return (
        <Layout>
            <Seo
                title={homePage.title}
                description={homePage.metaDescription}
            />
            <Header title={homePage.heading} maxWidth="md" />
            <StyledContainer maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Insecurity Insight
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {ReactHtmlParser(homePage.description)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {maps.map(map => (
                            <MapCard
                                key={map.node.frontmatter.title}
                                {...map.node.frontmatter}
                            />
                        ))}
                    </Grid>
                </Grid>
            </StyledContainer>
            <Footer />
        </Layout>
    );
}
export const query = graphql`
    query indexPageQuery {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        heading
                        type
                        description: homePageDescription
                        metaDescription
                        thumbnail {
                            childImageSharp {
                                fixed(width: 150, height: 150) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
